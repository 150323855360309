"use strict";

(function ($) {
  var A42 = {
    'home': {
      finalize: function finalize() {}
    },
    // All pages
    'common': {
      init: function init() {
        $.getScript('https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.3');
        var a = null;
        $(window).resize(function () {
          if (a !== null) clearTimeout(a);
          a = setTimeout(function () {
            FB.XFBML.parse();
          }, 1000);
        });
      },
      finalize: function finalize() {
        var fixedCtas = document.querySelectorAll('.body-fixed-cta .button');

        for (var i = 0; i < fixedCtas.length; i++) {
          var spanLength = fixedCtas[i].querySelector('span').getBoundingClientRect().width;
          fixedCtas[i].style.left = '-' + spanLength + 'px';
        }

        objectFitImages(null, {
          watchMQ: true
        });
        var svgElements = document.querySelectorAll('svg');
        svgElements.forEach(function (elem) {
          elem.setAttribute('preserveAspectRatio', 'xMaxYMax');
        });

        if (window.matchMedia("(max-width: 1200px)").matches) {
          $('.header-bottom ul.nav>.menu-item-has-children').on('click', function () {
            $(this).children('.sub-menu').slideToggle();
          });
        }

        $('.social-shares').click(function () {
          if ($('.medias-share').hasClass('visible')) $('.medias-share').removeClass('visible');else $('.medias-share').addClass('visible');
        });
        $('img').addClass('no-print');

        window.fbAsyncInit = function () {
          FB.init({
            appId: '2395086404055968',
            status: true,
            cookie: true,
            xfbml: true,
            version: 'v2.8'
          });
        };

        (function (d, debug) {
          var js,
              id = 'facebook-jssdk',
              ref = d.getElementsByTagName('script')[0];
          if (d.getElementById(id)) return;
          js = d.createElement('script');
          js.id = id;
          js.async = true;
          js.src = "//connect.facebook.net/en_US/all" + (debug ? "/debug" : "") + ".js";
          ref.parentNode.insertBefore(js, ref);
        })(document,
        /*debug*/
        false);

        function definePopupSize() {
          var width = 500;
          var height = 350;
          return ['resizable,scrollbars,status', 'height=' + height, 'width=' + width, 'left=' + window.innerWidth / 2 - width / 2, 'top=' + window.innerHeight / 2 - height / 2].join();
        }

        window.postToFB = function (title, desc, image) {
          FB.ui({
            method: 'feed',
            link: location.href,
            picture: image,
            name: title,
            description: desc
          }, function (res) {});
        };

        window.postToTwitter = function () {
          var win = window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(location.href), 'ShareOnTwitter', definePopupSize());
          win.opener = null; // 2
        };

        window.postToLinkedin = function () {
          var win = window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(location.href), 'ShareOnLinkedin', definePopupSize());
          win.opener = null; // 2
        };

        window.postToViadeo = function () {
          var win = window.open('https://www.viadeo.com/fr/widgets/share/preview?url=' + encodeURIComponent(location.href) + '&language=fr', 'ShareOnViadeo', definePopupSize());
          win.opener = null; // 2
        };

        $(window).load(function () {
          //==================================================================================
          // Header Responsive
          //==================================================================================
          $('.menu-burger').click(function () {
            $('.banner').addClass('visible');
            $('body').addClass('clipped');
          });
          $('.menu-burger-close').click(function () {
            $('.banner').removeClass('visible');
            $('body').removeClass('clipped');
          }); //=====
          // Parallax
          //=====
          // var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
          // function parallax() {
          //     if(!isMobile) {
          //         $('.slider-item').each(function() {
          //             var yPos = -(window.pageYOffset / 5);
          //             $(this).css('background-position', 'center ' + yPos + 'px');
          //         });
          //
          //         $('.number').each(function() {
          //             var slider = $(this);
          //
          //             var yPos = window.pageYOffset / 4;
          //             yPos = -yPos + slider.offset().top / 7;
          //
          //             $(this).css('background-position', 'center ' + yPos + 'px');
          //         });
          //
          //         var header = $('section.header .header-container');
          //         if(header.length > 0) {
          //             var yPos = -(window.pageYOffset / 4);
          //             header.css('background-position', 'center ' + yPos + 'px');
          //         }
          //     }
          // }
          //
          // window.addEventListener("scroll", function(){
          //     parallax();
          // });
          //==================================================================================
          // Différentes fonctions liées au slider OWL
          //==================================================================================

          $('section.slider .dot').click(function () {
            var element = $(this).attr('id');
            $('section.slider .dot').removeClass('active');
            $('section.slider .item').removeClass('active');
            $(this).addClass('active');
            $('section.slider .' + element).addClass('active');
          });
          $(".card__wrapper").owlCarousel({
            autoplay: false,
            autoplayTimeout: 5000,
            smartSpeed: 1750,
            loop: true,
            dots: true,
            nav: true,
            margin: 30,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 3
              }
            }
          });
          $('.partner-owl').owlCarousel({
            loop: true,
            margin: 0,
            nav: true,
            navText: ["<img src='/wp-content/uploads/2019/05/PICTO-fleche-gauche-partenaire.png' alt='précédent'>", "<img src='/wp-content/uploads/2019/05/PICTO-fleche-droite-partenaires.png' alt='suivant'>"],
            dots: false,
            autoplay: true,
            autoplayTimeout: 5000,
            smartSpeed: 1750,
            responsive: {
              0: {
                items: 1
              },
              768: {
                items: 2
              },
              1200: {
                items: 3
              }
            }
          });
          $('.slider-1-item').owlCarousel({
            loop: true,
            items: 1,
            margin: 0,
            center: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 7000,
            smartSpeed: 1750
          });
          $('.slider-3-items').owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 6000,
            smartSpeed: 1750,
            responsive: {
              0: {
                items: 1
              },
              768: {
                items: 3
              }
            }
          });
          $('.slider-4-items').owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 6000,
            smartSpeed: 1750,
            responsive: {
              0: {
                items: 1
              },
              768: {
                items: 3
              },
              1200: {
                items: 4
              }
            }
          });
          /* Slider Équipe */

          $('.team .owl-carousel').owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 5000,
            smartSpeed: 1750,
            responsive: {
              0: {
                items: 1
              },
              768: {
                items: 3
              }
            }
          });
          /* Slider Maître Apprenti */

          $('body.devenir-maitre-dapprentissage .owl-carousel').owlCarousel({
            loop: true,
            items: 1,
            margin: 0,
            center: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 6000,
            smartSpeed: 1750
          }); //==================================================================================
          // Header Search Form
          //==================================================================================

          $('.header-search-icon').click(function () {
            if ($('.header-search-form').hasClass('active')) $('.header-search-form').removeClass('active');else $('.header-search-form').addClass('active');
          }); //==================================================================================
          // Class <select> wpcf7
          //==================================================================================

          $('select').parent().addClass('form-select'); //==================================================================================
          // Recherche de formations
          //==================================================================================

          var statusValue = $('.find-formation-form select[name="status"]');
          statusValue.on('change', function () {
            if (statusValue.val() === '0') {
              $('.find-formation-form select[name="grade"] option[value="1"]').prop('disabled', false);
              $('.find-formation-form select[name="grade"] option[value="2"]').prop('disabled', true);
              $('.find-formation-form select[name="grade"] option[value="3"]').prop('disabled', true);
              $('.find-formation-form select[name="grade"] option[value="4"]').prop('disabled', true);
            } else if (statusValue.val() === '1') {
              $('.find-formation-form select[name="grade"] option[value="1"]').prop('disabled', false);
              $('.find-formation-form select[name="grade"] option[value="2"]').prop('disabled', false);
              $('.find-formation-form select[name="grade"] option[value="3"]').prop('disabled', false);
              $('.find-formation-form select[name="grade"] option[value="4"]').prop('disabled', true);
            } else {
              $('.find-formation-form select[name="grade"] option[value="1"]').prop('disabled', false);
              $('.find-formation-form select[name="grade"] option[value="2"]').prop('disabled', false);
              $('.find-formation-form select[name="grade"] option[value="3"]').prop('disabled', false);
              $('.find-formation-form select[name="grade"] option[value="4"]').prop('disabled', false);
            }
          });
          $('.pum .pum-content a').click(function () {
            $('.pum').css({
              'opacity': '0',
              'visibility': 'hidden'
            });
          }); //==================================================================================
          // Results Page
          //==================================================================================
          // $('.wpcf7-form input, .wpcf7-form textarea').on('focus', function() {
          //     $(this).closest('div').find('label').css({'top':'10px', 'font-size':'12px'});
          // });
          // $('.wpcf7-form input, .wpcf7-form textarea').on('focusout', function() {
          //     $(this).closest('div').find('label').css({'top':'35px', 'font-size':'14px'});
          // });
          //==================================================================================
          // Parallaxe rellax !
          //==================================================================================
          // new Rellax('.rellax');
        });
      }
    }
  };
  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = A42;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      UTIL.fire('common');
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);
})(jQuery);